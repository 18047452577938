<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse">Kembali</button>
        </span>
      </h3>
      <hr>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">

          <gtTabs>

            <gtTab title="Detail Surat">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Tipe Surat</small>
                <h6>{{ state.detail.id_typesurat === "" ? '-' : state.detail.id_typesurat }}</h6>
                <small class="text-muxed">Tanggal Diterima</small>
                <h6>{{ tanggalditerima === "" ? '-' : tanggalditerima }}</h6>
                <small class="text-muxed">AsalSurat</small>
                <h6>{{ state.detail.asalsurat === "" ? '-' : state.detail.asalsurat }}</h6>
              </div>

            </gtTab>

            <gtTab title="File Terlampir">
              <div class="col-md-6 pull-left"></div>
              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers"
                    :onAction="get"
                    :onDetail="doDetail"
                    :onEdit="doEdit"
                    @onDelete="doDelete"
                    :forbidEdit="forbidUpdate"
                    :forbidDetail="forbidRead"
                    :forbidDelete="forbidDel"
                  />
                </div>
              </div>
            </gtTab>

            <gtTab title="Tracking"></gtTab>

            <gtTab title="History"></gtTab>

          </gtTabs>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs
  },

   data() {
    return {
      headers: {
        
        nama_file: {
          label: "Nama File",
          sortable: false
        },
        nama_pengunggah: {
          label: "Nama Pengunggah",
          sortable: false
        },
        tanggal_upload: {
          label: "Tanggal Upload",
          sortable: false
        }

      }
    };
  },

  computed: {

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.asalSurat;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("asalSurat/STATE", state);
      this.$store.dispatch("asalSurat/getAsalSuratById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("asalSurat/onCancel");
    }
  }
};
</script>
